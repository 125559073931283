body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {text-transform: uppercase;}
.btnMarg20 {margin:10px 0!important}
h3.MuiTypography-h3 {font-size:20px;font-weight: 600;}
h2.MuiTypography-h2 {font-size:20px;font-weight: 600;}
h6, h5, h3{margin:0 0 20px 0!important;padding: 0;display: block;}
.MuiTabs-indicator {background-color: #00a0e9!important;}
input {font-size: 14px; font-family: 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;}
body, .personCard {background-color: #efefef;}
.gridPadding {padding:20px 0}
.gridCenter {margin:0 auto!important}
.header {background-color: #f8d530; font-size: 14px;}
header {display: flex;align-items: center;justify-content: space-between;padding: 0 20px;}
header a,
.mobileNav a,
.profileNavMobile a,
.headLink {
  text-decoration: none;
  color: #000;
  padding: 10px;
  vertical-align: top;
  border:0;
  background: transparent;
  font-size: 14px;
}
.mobileNav a {
  display: flex;
}
.mobileNav a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.headerLeft .headLink {line-height: 40px;}
.headerLeft a, .dropdown {height:100%;line-height: 58px;}
.headLinkActive, .headLink:hover{background: rgba(255, 255, 255, 0.3); cursor: pointer;}
.headLinkActiveMob {background: rgba(0, 0, 0, 0.1);}
.headerLeft, header {height: 60px; display: flex; align-items: center;}
header h1 {margin:0;display: inline-block;vertical-align: middle;font-weight: 500;font-size:22px}
header nav {display: inline-flex;margin:0 0 0 20px;vertical-align: middle;height: 100%;align-items: center;}
.headerLeft .headLinkTimer,
.mobileNav .headLinkTimer {
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  text-align: center;
  line-height: 2.2;
  box-sizing: border-box;
  border:0;
}
.headLinkTimerWithTroubles {
  background-color: #e94949;
  color: #fff;
}
.headLinkTimerWithoutTroubles {
  background-color: #4caf50;
  color: #fff;
}
.mainContent {padding: 0;box-sizing: border-box;margin: 0;}
.swal-button--confirm {border:0;background-color: #00a0e9;}
.swal-footer {text-align: center;}
.pageContent {padding:30px 20px;box-sizing: border-box;}
.pageContent h1 {font-size: 28px;margin:0 0 20px 0;}
.customInp {margin:0 0 10px 0}
.customInp input, .customInpDate {padding:0 12px;box-sizing: border-box;background-color: #fff;border:1px solid #fff;height: 50px;border-radius: 5px;width:100%}
.customInp .MuiInput-underline:before {border: 0;}
.inpLabel {color:#808080;margin:0 0 5px 0 }
.customInp input:focus {border-color:#00a0e9;outline:none}
.customInp .MuiInput-underline:hover:not(.Mui-disabled):before,.MuiInput-underline:after {border: 0!important;}
.menuNav {display: inline-block;vertical-align: middle;margin:0 20px 0 0}
.buttonIcon {padding: 0 10px;}
.profileNavMobile {min-width:200px}
.profileNavMobile button {margin:0;border-radius: 0;position: absolute;bottom:0;left:0;}
.headIcon {width:30px;height: 30px;color:#00a0e9;margin:0 0 0 15px;cursor: pointer;}
.headIcon:hover {opacity:.7}
.headIcon svg{font-size: 30px;padding: 0;}
.iconRed {color:#e94949}
.iconBlue {color:#00a0e9;vertical-align: middle;}
.copyIcon {visibility: hidden;vertical-align: middle;padding:5px 0 0 10px;display: inline-block;}
.iconBlue:hover .copyIcon{visibility: visible;}
.personCard {min-width: 400px;padding:30px;height: 100%;box-sizing: border-box;}
.iconExit {margin:0 0 0 20px}
.dropdown svg {vertical-align: middle;}
.subMenu a {font-size:15px}
.subMenu  li {display: block;white-space:unset}
.stylePurple {color:#9b2f7b}
.styleGrey {color:#808080}
.profileRels {margin:20px 0}
.personRels {display: flex;justify-content: space-between;align-items: center;}
.personRels button{border-radius: 50%;height: 35px;width:35px;padding: 0;min-width: 30px;}
.pageContent .PrivateTabIndicator-colorSecondary-3 {background-color: #00a0e9;}
.customTabs {margin:0 0 30px 0}
.formFields {display: grid;grid-template-columns: repeat(4,1fr);grid-gap:20px;align-items:flex-start}
.customInp .MuiInputBase-root{background-color: #fff;border:0;border-radius: 5px;box-sizing: border-box;margin:0}
.customInp input {padding: 0 10px;}
.customInp .MuiFormLabel-root {padding: 0 0 0 10px;}
.customInp .MuiInputLabel-shrink  {line-height: unset;}
.customInp .MuiInputLabel-formControl {transform: translate(0, 18px) scale(1);}
.customInp .MuiInputLabel-shrink, .customInpT .MuiInputLabel-filled.MuiInputLabel-shrink, .customSelect .MuiInputLabel-filled.MuiInputLabel-shrink {transform: translate(0, -15px) scale(0.75)}
.customInp .react-datepicker-wrapper, .customSelect .MuiFormControl-root {width: 100%;}
.customSelect .MuiInputBase-root:before, .customSelect .MuiFilledInput-root:hover:before,
.customSelect .MuiFilledInput-root.Mui-focused:before{border:0}
.customSelect .MuiFilledInput-root:before {content: ""!important;}
.customSelect .MuiFilledInput-root, .customSelect .MuiFilledInput-root:hover,
.customSelect .MuiFilledInput-root.Mui-focused, .customSelect .MuiSelect-root:focus, .react-datepicker-wrapper {background-color: #fff;border-radius: 5px;}
.customSelect .MuiSelect-root:focus, .MuiFilledInput-underline:after, .MuiInputBase-root:after, .MuiInputBase-root::before {border:0!important}
.customSelect .MuiSelect-select  {padding:15px 12px 16px 10px}
.noMarg {margin:0!important}
.react-datepicker-popper {z-index:9!important;}
.customInpT input {background-color: transparent;}
.MuiFilledInput-root.Mui-focused, .MuiFilledInput-root:hover, .react-datepicker-wrapper {background-color: #fff!important;}
.customSelectAuto {margin:0 0 20px 0}
.customSelectAuto input{height: 40px;padding: 0 10px!important;}
.css-1wa3eu0-placeholder {padding:0 10px}
.customSelectAuto .css-26l3qy-menu {z-index: 999;}
.customSelectAuto .css-yk16xz-control {border:0}
.styleSuccess {
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
  padding: 5px;
  border-radius: 5px;
}
.styleWarning {
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229);
  padding: 5px;
  border-radius: 5px;
}
.styleInfo {
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
  padding: 5px;
  margin: 2px 0;
  border-radius: 5px;
}
.styleErr {
  background-color: rgb(253, 236, 234);
  color: rgb(97, 26, 21);
  padding: 5px;
  border-radius: 5px;
}
.swal-text { text-align: center; }
.styleErrBackgr {background-color: #ffb3b3;}
table .MuiTableCell-head  {color:#000}
.MuiTableContainer-root td{background-color: #fff;}
table svg {color:#00a0e9}
.flexFullCenterRow {display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.MuiButton-textSizeSmall {height: 30px!important;font-size:12px}
.modalClose {position:absolute!important;right:10px;top:0;z-index:9;}
.flex {display: flex;align-items: center;}
.gridSmall {display: grid;grid-template-columns:100px 1fr;margin:15px 0;grid-gap:10px}
.grid1fr6 {display: grid;grid-template-columns:repeat(6,1fr);grid-gap:10px;}
.grid1fr8 {display: grid;grid-template-columns:repeat(8,1fr);grid-gap:10px;align-items: center;}
.grid1fr2 {display: grid;grid-template-columns:repeat(2,1fr);grid-gap:10px;align-items: center;}
.gridSmall2 {display: grid;grid-template-columns:1fr 100px;grid-gap:10px;align-items: center;}
.MuiDialogContent-root {padding:30px 20px!important}
.gridHalf {display: grid;grid-template-columns:repeat(2,1fr);grid-gap:10px}
.gridThreeColumn {display: grid;grid-template-columns: 5fr 3fr 3fr; grid-gap:10px}
.MuiDialogContent-root .MuiFormControl-root{padding: 0 10px;border-radius: 5px;}
.fillInp .MuiFormControl-root{background-color:transparent;padding: 0;}
.fillInp .MuiSelect-filled.MuiSelect-filled {border-radius: 5px;}
.styleSuctext {color:#499e5e}
.lineHeight3 {line-height: 3;}
.fullWidth {width:100%}
.gridSpan2 {grid-column:span 2;}
/* .MuiDialogContent-root .customInpMask input{background-color: #f8f7f7;} */
.stepTitle {margin:10px 0 30px 0;font-size:18px}
.dialogContent {border-top:1px solid #c5c4c4;border-bottom:1px solid #c5c4c4;padding: 0 0 20px 0;}
.filesGrid {padding: 20px;}
.filesGrid .gridHalf{margin:0 0 20px 0}
.filesGrid .gridHalf:last-child{margin:0}
.emptyInput {border-color:red!important}
.header.mypost {background-color: #c04e9f;color:#fff}
.header.onepay {background-color: #808080;color:#fff}
.header.mypost a, .header.onepay a, .header.elma, .header.elma a, .header.bitrix, .header.bitrix a{color:#fff}
.header.elma {background-color: #50ac67;}
.header.bitrix {background-color:#3bc8f5}
.faqDel {position:absolute;right:50;top:0}
.faqTab {margin:0 0 20px 0}


.header.mypost .headIcon.iconRed, .header.onepay .headIcon.iconRed {color:#f56E8b}
.header.onepay .headIcon.iconBlue{color:#b0ca62}
.header.elma .headIcon.iconBlue {color:#E6A86B}
.header.elma .headIcon.iconRed {color:#963023	}
.header.bitrix .headIcon.iconBlue{color:#b7db2a}

.backgrLightBLue {background-color: #a0d9f3;}
.backgrLightGrey {background-color: #f6f7f8;}
.searchResult {margin:30px 0 0 0}
.actionItem {border-radius: 10px;background-color: #fff;margin:0 0 30px 0;padding:20px}
.infoItem {margin:0 0 30px 0;}
.infoResult {background-color: #fff;padding: 20px;}
.infoItemGridIcon {display: grid;grid-template-columns: 40px 1fr;cursor: pointer;}
.infoLink {text-decoration: none;color:unset}
.noBold {font-weight: 400!important}
.contentCenter {text-align: center;margin:0 auto!important}
.customSelect .MuiSelect-root {text-align: left;}
.MuiTableRow-head th:first-child{border-top-left-radius: 10px;}
.MuiTableRow-head th:last-child {border-top-right-radius: 10px;}
.MuiRadio-colorPrimary.Mui-checked {color:#00a0e9!important}
.MuiTableBody-root tr:nth-child(2n+2) td{background-color: #fafafa;}
.MuiTableHead-root th {font-weight: 600;background-color: #e3e1e1;}
button.Mui-disabled {opacity: .6;}
.reportDetail {background-color: #fff;padding: 20px;max-height: 388px;overflow-y:scroll ;border-radius: 10px;}
.elmaBlock {background-color: #fafafa;margin:0 0 20px 0;padding: 15px;border-radius: 10px}
.customInp .Mui-error input,.customInp .Mui-error.MuiFilledInput-multiline, .customSelectErr .MuiInputBase-formControl {border:1px solid red}
.hideClass, .faqDelMob {display: none!important;}
.btnWithoutStyles {border: 0;background: transparent;cursor: pointer;font-size: 14px;}

@media all and (max-width: 768px) {
header h1, .pageContent h1 {font-size: 24px;}
.headerLeft {display: flex;align-items: center;}
.mobileNav{width:300px}
.mobileNav a, .profileNavMobile a  {display: block;height: auto;padding: 15px 20px;line-height: 1;}
.mobileNav .headLinkTimer {padding: 0;margin:10px 20px;line-height: 2;}
.iconExit {margin:0 0 0 20px}
.personCard {min-width: 300px;}
.headNavCenter {display: none;}
.formFields,.grid1fr6, .grid1fr8 {grid-template-columns:repeat(2,1fr);}
.gridHalf {grid-template-columns:1fr}
.faqDel {display: none!important;}
.faqDelMob {display: inline-block!important;margin-right:50px!important}
.faqSave {float:right}
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}